import type { BlockRoundedSection } from 'types/block-rounded-section';
import React from 'react';
import {
  Button,
  Card,
  CardProps,
  Wrap,
  ButtonLayoutProps,
  RichText,
} from '@leafwell/components';
import Figure from './figure';
import HeadingBody from './heading-body';

type RoundedSectionStyles = {
  card: string;
  content: string;
  image: string;
};

const RoundedSectionButton: React.FC<
  Pick<BlockRoundedSection, 'link' | 'color'> & {
    className?: string;
  }
> = ({ link: button, color: buttonVariant, className = '' }) => {
  return button ? (
    <Button
      href={button.url}
      label={button.title}
      rounded={true}
      target={button.target}
      variant={buttonVariant as ButtonLayoutProps['variant']}
      className={['self-end justify-self-start', className].join(' ')}
    />
  ) : null;
};

const RoundedSectionContent: React.FC<{
  className?: string;
  content: string;
  isTextLarge: boolean;
}> = ({ className, content, isTextLarge }) => (
  <RichText
    content={content}
    className={[
      'md:max-w-lg relative',
      isTextLarge ? '' : 'text-sm',
      className,
    ].join(' ')}
  />
);

const RoundedSection: React.FC<Omit<BlockRoundedSection, 'acfFcLayout'>> = ({
  sectionId,
  columns,
  title,
  titleFont,
  subtitle,
  content,
  contentRight,
  link,
  color = 'primary',
  image,
  imageAlignment,
  size = 'large',
  variant,
}) => {
  const hasSubtitle = subtitle?.length > 0;
  const showSubtitleColumn = hasSubtitle && columns === '3';
  const hasImage = image !== null;
  const imageCenter = hasImage && imageAlignment === 'center';
  const showContentRight = imageCenter && contentRight?.length > 0;
  const isLarge = size === 'large';
  const isTextLarge = size !== 'small';
  let cardVariant: string;

  if (variant === 'secondary') {
    cardVariant = 'light';
  } else if (variant === 'gradient' || variant === 'gradient-transparent') {
    cardVariant = 'medium';
  } else {
    cardVariant = variant;
  }

  const sizeStyles = {
    card: {
      default: [
        'relative md:gap-x-10 xl:gap-x-16 xl:px-16',
        columns === '3' ? 'md:grid-cols-3' : 'md:grid-flow-col',
      ].join(' '),
      small: 'md:items-center',
      medium: ['xl:py-16', hasImage && !imageCenter ? 'lg:mt-6' : ''].join(' '),
      large: ['xl:py-16', hasImage && !imageCenter ? 'lg:mt-10' : ''].join(' '),
    },
    image: {
      default: 'hidden md:block self-end',
      small: ['md:-mb-6 max-w-xxs', imageCenter ? '' : 'md:-mt-24'].join(' '),
      medium: [
        'md:-mb-10 xl:-mb-16 max-w-sm',
        imageCenter ? '' : 'md:-mt-24',
      ].join(' '),
      large: [
        'max-w-xl md:-mb-10 xl:-mb-16',
        imageCenter ? '' : 'md:-mt-36',
      ].join(' '),
    },
    content: {
      default: 'relative grid gap-y-3 content-start',
      small: '',
      medium: 'lg:gap-y-6',
      large: 'lg:gap-y-6',
    },
  };

  const sizeClassNames = Object.keys(sizeStyles).reduce(
    (result: RoundedSectionStyles, key) => {
      result[key] = `${result[key]} ${sizeStyles[key][size]}`;

      return result;
    },
    {
      card: sizeStyles.card.default,
      content: sizeStyles.content.default,
      image: sizeStyles.image.default,
    },
  );
  let alignmentClassNames: string;

  if (imageAlignment === 'center') {
    alignmentClassNames =
      'h-full !absolute w-3/6 left-1/2 -translate-x-1/2 top-0';
  } else if (imageAlignment === 'left') {
    alignmentClassNames = 'md:order-first';
  } else {
    alignmentClassNames = 'md:justify-self-end';
  }

  return (
    <Wrap
      size={isLarge ? 'large' : 'default'}
      id={sectionId}
      className="rounded-section"
      tagName="div"
    >
      <Card
        size={size === 'small' ? 'medium' : 'large'}
        filled={true}
        rounded={true}
        variant={cardVariant as CardProps['variant']}
        className={[
          sizeClassNames.card,
          showSubtitleColumn || showContentRight ? 'md:grid-cols-3' : '',
          variant === 'gradient' || variant === 'gradient-transparent'
            ? 'before:absolute before:md:w-3/4 before:h-full before:md:right-0 before:md:bottom-0 before:md:rounded-2xl before:bg-gradient-to-b before:from-lilac-100 before:via-indigo-100 before:to-green-300 before:gradient-mask-l-0'
            : '',
          variant === 'gradient-transparent'
            ? 'lg:bg-transparent lg:border-transparent'
            : '',
        ].join(' ')}
      >
        {showSubtitleColumn && (
          <HeadingBody
            level="6"
            title={subtitle}
            className={isLarge ? 'lg:-mb-3' : ''}
          />
        )}
        <div className={sizeClassNames.content}>
          {hasSubtitle && !showSubtitleColumn && (
            <HeadingBody
              level="6"
              title={subtitle}
              className={isLarge ? 'lg:-mb-3' : ''}
            />
          )}
          <HeadingBody
            level={isTextLarge ? '3' : '4'}
            title={title}
            tagName="h3"
            className={[
              'md:max-w-md',
              titleFont === 'heading' ? '!font-heading' : '',
            ].join(' ')}
          />
          <RoundedSectionContent content={content} isTextLarge={isTextLarge} />
          {hasImage && (
            <RoundedSectionButton
              link={link}
              color={color}
              className="mt-1 w-full md:w-fit"
            />
          )}
        </div>
        {!hasImage && (
          <RoundedSectionButton
            link={link}
            color={color}
            className="md:justify-self-end"
          />
        )}
        {hasImage && (
          <Figure
            {...image}
            className={[
              'self-end',
              sizeClassNames.image,
              alignmentClassNames,
            ].join(' ')}
            objectFit={imageCenter ? 'cover' : 'contain'}
          />
        )}
        {showContentRight && (
          <RoundedSectionContent
            className="md:pt-16 md:col-start-3"
            content={contentRight}
            isTextLarge={isTextLarge}
          />
        )}
      </Card>
    </Wrap>
  );
};

export default RoundedSection;
